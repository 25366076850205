import _ from 'lodash'
import { CCol, CRow } from '@coreui/react'
import React from 'react'
import BudgetCard from '../BudgetCard/BudgetCard'

import styles from './invoiceBudgetComponent.module.scss'
import PropTypes from 'prop-types'

const InvoiceBudgetComponent = ({ budget }) => {
  const monthlyBudget = _.get(budget, 'monthly')
  const annualBudget = _.get(budget, 'annual')

  const budgetCardData = [
    { title: 'Monthly', value: monthlyBudget },
    { title: 'Annually', value: annualBudget },
  ]

  const renderBudgetCards = () =>
    budgetCardData.map(({ title, value }) => (
      <CCol key={title}>
        <BudgetCard title={title} value={value} />
      </CCol>
    ))

  return (
    <>
      <div className={styles.titleStyle}>Budget</div>
      <div>
        <div className={styles.subtitleStyle}>Account Budgets</div>
        <div className={styles.subtitleTextStyle}>
          See blow estimated budgets for this eyar and next year based on your current order value
          for all your workplaces on this account
        </div>
      </div>
      <CRow md={{ cols: 2 }}>{renderBudgetCards()}</CRow>
    </>
  )
}

InvoiceBudgetComponent.defaultProps = {
  budget: { monthly: 0, annual: 0 },
}

InvoiceBudgetComponent.propTypes = {
  budget: PropTypes.object,
}

export default InvoiceBudgetComponent
