import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'
import { CCard, CCardBody, CCardTitle } from '@coreui/react'
import styles from './accountcard.module.scss'
const AccountCard = ({ accountName, accountNumber, accountStatus, accountCreated }) => {
  return (
    <CCard className={styles.clientCard}>
      <CCardTitle className={styles.cardTitle}>
        {accountName ? accountName : 'Loading...'}
      </CCardTitle>
      <CCardBody className={styles.cardBody}>
        <Chip
          key={accountNumber ? accountNumber : '000000000'}
          variant="filled"
          label={accountNumber ? accountNumber : '000000000'}
          className={styles.chipLayout}
        />
        <div className={styles.cardDetailsContainerDiv}>
          <div>
            <div className={styles.cardDetailsTitle}>Status</div>
            <div className={styles.cardBulletpointDetail}>
              <div
                className={
                  accountStatus === 'Active'
                    ? styles.columnmenustatusActive
                    : styles.columnmenustatusInactive
                }
              />
              {accountStatus ? accountStatus : 'Inactive'}
            </div>
          </div>
          <div>
            <div className={styles.cardDetailsTitle}>Date joined</div>
            <div className={styles.cardBulletpointDetail}>
              {accountCreated.isValid() ? accountCreated.format('DD-MM-YYYY') : '#'}
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
  )
}

AccountCard.defaultProps = {
  accountName: '',
  accountNumber: '',
  accountStatus: '',
  accountCreated: {},
}

AccountCard.propTypes = {
  accountName: PropTypes.string,
  accountNumber: PropTypes.string,
  accountStatus: PropTypes.string,
  accountCreated: PropTypes.object,
}

export default AccountCard
