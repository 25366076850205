import React from 'react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import InvoiceEntry from '../InvoiceEntry/InvoiceEntry'
import { CCard, CCardHeader, CListGroup } from '@coreui/react'

import styles from './invoicelist.module.scss'

const InvoiceList = ({ invoiceList, openInvoiceDetails, isAccountPostBill, downloadInvoice }) => {
  return invoiceList.length ? (
    <CCard className={styles.cardStyle}>
      <CCardHeader className={styles.containerHeaderDiv}>
        <div className={styles.headerStyle}>
          <div>Invoice Date</div>
          <div>Invoice Period</div>
          <div>Total</div>
          <div>Invoice</div>
          <div>Reports</div>
          <div>Detail</div>
        </div>
      </CCardHeader>
      <div className={styles.invoiceEntriesContainerDiv}>
        <CListGroup flush>
          {invoiceList.map((invoice, index) => {
            return (
              <InvoiceEntry
                key={index}
                data={invoice}
                openInvoiceDetails={openInvoiceDetails}
                isAccountPostBill={isAccountPostBill}
                downloadInvoice={downloadInvoice}
              />
            )
          })}
        </CListGroup>
      </div>
    </CCard>
  ) : null
}

InvoiceList.defaultProps = {
  invoiceList: [],
  openInvoiceDetails: noop,
  downloadInvoice: noop,
  isAccountPostBill: false,
}

InvoiceList.propTypes = {
  invoiceList: PropTypes.array,
  downloadInvoice: PropTypes.func,
  openInvoiceDetails: PropTypes.func,
  isAccountPostBill: PropTypes.bool,
}

export default InvoiceList
