import _ from 'lodash'
import { fetchRetry, getHttpHeaders, httpGetRequest, newApiUrl } from 'src/utils/apiUtils'

export async function getInvoicesApiCall(data) {
  return await httpGetRequest(getInvoicesApiCallInfo.bind(this, data))
}

async function getInvoicesApiCallInfo(data) {
  if (!data) {
    return Promise.reject('Invalid Account Id')
  }
  const [region, account_id] = data.split('-')
  const init = getHttpHeaders('GET')
  const response = await fetchRetry(
    `${newApiUrl}/api/invoices?accountId=${account_id}&region=${region}`,
    init,
  )
  return response
}

export async function getInvoiceDetailsApiCall(data) {
  return await httpGetRequest(getInvoiceDetailsApiCallInfo.bind(this, data))
}

async function getInvoiceDetailsApiCallInfo(data) {
  if (!data) {
    return Promise.reject('Invalid Invoice Id')
  }
  const [region, invoice_id] = data.split('-')
  let init = getHttpHeaders('GET')
  const response = await fetchRetry(
    `${newApiUrl}/api/invoices/${invoice_id}?region=${region}`,
    init,
  )
  return response
}

export async function getInvoiceReportApiCall(data) {
  return await httpGetRequest(getInvoiceReportApiCallInfo.bind(this, data))
}

export async function getInvoiceReportApiCallInfo(data) {
  const {
    invoiceNo,
    fruitful_account_id,
    isPreBill,
    isAdjustment,
    dates,
    isMultiAccount,
    isAdminSnapshot,
  } = data
  const [region, accountId] = fruitful_account_id.split('-')

  let datesQuery = ''
  if (!_.isEmpty(dates?.start) && !_.isEmpty(dates?.end)) {
    datesQuery = `&start=${dates.start.format('YYYY-MM-DD')}&end=${dates.end.format('YYYY-MM-DD')}`
  }

  if (_.isEmpty(fruitful_account_id) || (_.isEmpty(invoiceNo) && _.isEmpty(datesQuery))) {
    return Promise.reject('Missing Invoice Parameters')
  }

  let url = `${newApiUrl}/api/reports/${
    invoiceNo ? `invoice/${invoiceNo}/` : `account/${accountId}/`
  }?region=${region}&accountId=${accountId}`

  if (invoiceNo) {
    url += `${isPreBill ? '&isPreBill=true' : ''}${
      isAdjustment ? '&isAdjustmentBreakdown=true' : ''
    }`
  } else {
    if (!_.isEmpty(datesQuery)) {
      url += `${datesQuery}`
    }
    if (isMultiAccount) {
      url += `&isMultiAccount=true`
    }
    if (isAdminSnapshot) {
      url += `&isAdminSnapshot=true`
    }
    if (isPreBill) {
      url += `&isPreBill=true`
    }
  }

  let init = getHttpHeaders('GET')
  const response = await fetch(url, init)
  return response
}
