import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { CCard, CCardBody, CCardImage } from '@coreui/react'
import AccountCard from '../AccountCard/AccountCard'
import ProductListIcon from '../../assets/dashboard_icons/dashboard-img@3x.png'
import styles from './accountrow.module.scss'
import moment from 'moment'
import { Buffer } from 'buffer'

import { ReactComponent as ClientIcon } from '../../assets/dashboard_icons/client.svg'
import { ReactComponent as TreeIcon } from '../../assets/dashboard_icons/trees.svg'
import { useQuery } from '@tanstack/react-query'
import { fileTypeFromBuffer } from 'file-type'
import { CircularProgress } from '@mui/material'
import { getHttpHeaders, newApiUrl } from 'src/utils/apiUtils'
import MarketingImage from '../MarketingImg/MarketingImg'

const AccountRow = ({
  accountName,
  accountNumber,
  accountStatus,
  accountCreated,
  clientSince,
  totalTreeContributions,
}) => {
  return (
    <div className={styles.parentDiv}>
      <div className={styles.middleColPadding}>
        <AccountCard
          accountName={accountName}
          accountNumber={accountNumber}
          accountStatus={accountStatus}
          accountCreated={accountCreated}
        />
      </div>
      <div className={styles.middleColPadding}>
        <CCard className={styles.clientCard}>
          <CCardBody style={{ padding: '0px', display: 'grid', gridTemplateColumns: '1fr' }}>
            <div className={styles.contentContainerDiv}>
              <div className={styles.cardColDashBoardIcons}>
                <ClientIcon className={styles.cardDashBoardIcons} />
              </div>
              <div>
                <div className={styles.cardDashBoardTitle}>
                  {clientSince.isValid() ? moment(clientSince).format('YYYY') : '#'}
                </div>
                <div className={styles.cardDashBoardDetails}>Client since</div>
              </div>
            </div>
            <div className={styles.contentContainerDiv}>
              <div className={styles.cardColDashBoardIcons}>
                <TreeIcon className={styles.cardDashBoardIcons} />
              </div>
              <div>
                <div className={styles.cardDashBoardTitle}>
                  {totalTreeContributions ? totalTreeContributions : 0}
                </div>
                <div className={styles.cardDashBoardDetails}>Your tree contributions</div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
      <div className={styles.middleColPadding}>
        <CCard className={styles.imageCardStyle}>
          <MarketingImage accountNumber={accountNumber} />
        </CCard>
      </div>
    </div>
  )
}

AccountRow.defaultProps = {
  accountName: '',
  accountNumber: '',
  accountStatus: '',
  accountCreated: {},
  clientSince: {},
  totalTreeContributions: 0,
}

AccountRow.propTypes = {
  accountName: PropTypes.string,
  accountNumber: PropTypes.string,
  accountStatus: PropTypes.string,
  accountCreated: PropTypes.object,
  clientSince: PropTypes.object,
  totalTreeContributions: PropTypes.number,
}

export default AccountRow
