import _ from 'lodash'
import moment from 'moment'
import { dbRegionId } from './commonUtils'

export const updateObjectRecursive = (obj2update, keyPath, value) => {
  let auxObj = obj2update ? obj2update : {}
  let lastKeyIndex = keyPath.length - 1
  let key = keyPath[0]
  if (!lastKeyIndex) {
    auxObj[key] = value
    return auxObj
  }
  auxObj[key] = updateObjectRecursive(auxObj[key], keyPath.slice(1), value)
  return auxObj
}

export const multipleRegions = (token) => {
  let base64Url = token.split('.')[1]
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  let payload = JSON.parse(jsonPayload)

  let accountList = dbRegionId
    .map((regionIdentifier) => {
      if (payload[regionIdentifier]) {
        let aux = payload[regionIdentifier].split(',')
        return aux
      }
      return []
    })
    .flat()

  if (accountList.length > 1) {
    return true
  }
  return false
}

export const nextDeliveryDateComputation = (activitiesArray) => {
  if (!activitiesArray.length) {
    return moment().format('MMMM D, YYYY dddd')
  }
  const futureActivites = activitiesArray.filter(
    (activities) => activities.fo_order_activity_status === 'Scheduled',
  )
  const uniqueDates = _.uniq(_.map(futureActivites, 'DispatchDate'))

  const currentDate = moment()
  const nearestDate = uniqueDates.reduce((previousDate, currentDateValue) => {
    const diff = moment(currentDateValue).diff(currentDate)
    if (diff > 0) {
      if (!previousDate) {
        return currentDateValue
      }
      if (moment(currentDateValue).diff(moment(previousDate)) < 0) {
        return currentDateValue
      }
    }
    return previousDate
  }, '')
  if (!nearestDate) {
    return moment().format('MMMM D, YYYY dddd')
  }
  return moment(nearestDate, 'YYYY-MM-DD').format('MMMM D, YYYY dddd')
}

export const StopOrderCollectionDate = (submittingDetails, activities) => {
  const { effective_date } = submittingDetails
  if (!activities.length) {
    return moment().format('MMMM D, YYYY dddd')
  }
  const momentDate = effective_date ? moment(effective_date, 'YYYY-MM-DD') : moment()
  const futureActivites = activities.filter(
    ({ fo_order_activity_status, productname }) =>
      fo_order_activity_status === 'Scheduled' && !productname.includes('(DON)'),
  )
  const uniqueDates = _.uniq(_.map(futureActivites, 'DispatchDate'))
  const nearestDate = uniqueDates.reduce((previousDate, currentDateValue) => {
    const diff = moment(currentDateValue).diff(momentDate)
    if (diff > 0) {
      if (!previousDate) {
        return currentDateValue
      }
      if (moment(currentDateValue).diff(moment(previousDate)) < 0) {
        return currentDateValue
      }
    }
    return previousDate
  }, '')
  if (!nearestDate) {
    return moment().format('MMMM D, YYYY dddd')
  }
  return moment(nearestDate, 'YYYY-MM-DD').format('MMMM D, YYYY dddd')
}

export const returnAvailableTime = () => {
  const times = []
  for (let hour = 0; hour <= 12; hour++) {
    times.push(moment({ hour }).format('H:mm'))
    if (hour < 12) {
      ;[15, 30, 45].forEach((time) => {
        times.push(
          moment({
            hour,
            minute: time,
          }).format('H:mm'),
        )
      })
    }
  }
  return times
}

export const getOfficeFilter = (state) => {
  const officeFilter = state?.workplace
  if (!officeFilter) {
    return 'All Workplaces'
  }
  return officeFilter
}

export const getPageColor = (state) => {
  let pageColor = state?.color || {
    color: '#A5cd38',
    workplaceNameBackgroundColor: 'RGBA(165, 205, 56, 0.15)',
    productBackgroundColor: 'RGBA(165, 205, 56, 0.05)',
  }
  return pageColor
}

export const formatDates = (date, format = 'DD-MM-YYYY') => {
  if (!date) {
    return ''
  }
  const momentDate = moment(date, 'YYYY-MM-DD')
  return momentDate.format(format)
}
