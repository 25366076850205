import React from 'react'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

import { CListGroupItem } from '@coreui/react'
import { ReactComponent as SearchIcon } from '../../assets/invoice_icons/detail.svg'

import styles from './invoiceentry.module.scss'
import { formatDates } from 'src/utils/helperFunctions'
import { formatPrice } from 'src/utils/commonUtils'

const InvoiceEntry = ({ data, openInvoiceDetails, isAccountPostBill, downloadInvoice }) => {
  const { fruitful_invoice_id, invoicedate, StartDate, EndDate, hdnGrandTotal } = data
  return (
    <CListGroupItem className={styles.cardGroupEntry}>
      <div className={styles.invoiceEntry}>
        <div className={styles.entryStyle}>{formatDates(invoicedate)}</div>
        <div className={styles.entryStyle}>
          {formatDates(StartDate)} to {formatDates(EndDate)}
        </div>
        <div className={styles.entryStyle}>{formatPrice(hdnGrandTotal)}</div>
        <div
          className={styles.entryDownloadStyle}
          onClick={(e) => {
            e.stopPropagation()
            openInvoiceDetails(fruitful_invoice_id)
          }}
        >
          Invoice summary
        </div>
        <div className={styles.reportDivStyle}>
          <div
            onClick={() => downloadInvoice(fruitful_invoice_id, invoicedate, !isAccountPostBill)}
          >
            Invoice breakdown
          </div>
          {isAccountPostBill ? (
            <></>
          ) : (
            <div onClick={() => downloadInvoice(fruitful_invoice_id, invoicedate, true, true)}>
              Prior month adjustment breakdown
            </div>
          )}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto',
            justifyItems: 'end',
            width: '100%',
            paddingRight: '5px',
          }}
        >
          <SearchIcon
            className={styles.searchStyle}
            onClick={(e) => {
              e.stopPropagation()
              openInvoiceDetails(fruitful_invoice_id)
            }}
          />
        </div>
      </div>
    </CListGroupItem>
  )
}

InvoiceEntry.defaultProps = {
  data: {},
  openInvoiceDetails: noop,
  downloadInvoice: noop,
  isAccountPostBill: false,
}

InvoiceEntry.propTypes = {
  data: PropTypes.object,
  openInvoiceDetails: PropTypes.func,
  downloadInvoice: PropTypes.func,
  isAccountPostBill: PropTypes.bool,
}

export default InvoiceEntry
