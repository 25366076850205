import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material'
import { CCardImage } from '@coreui/react'
import { Buffer } from 'buffer'
import { fileTypeFromBuffer } from 'file-type'
import { getHttpHeaders, newApiUrl } from 'src/utils/apiUtils'
import ProductListIcon from '../../assets/dashboard_icons/dashboard-img@3x.png'
import styles from './marketingimage.module.scss'

const MarketingImage = ({ accountNumber }) => {
  const [marketingImg, setMarketingImg] = useState({
    src: null,
    extension: null,
    link: null,
  })

  const marketingImgReq = useQuery({
    enabled: !_.isEmpty(accountNumber) && accountNumber.split('-').length > 1,
    retry: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['marketingImg', { region: accountNumber.split('-')[0] }],
    queryFn: async ({ queryKey }) => {
      const [, { region }] = queryKey
      const init = getHttpHeaders('GET')
      const response = await fetch(`${newApiUrl}/api/marketing/image?region=${region}`, init)
      if (response.ok) {
        return response.json()
      } else {
        return undefined
      }
    },
    select: (data) => {
      if (data?.Response === 'Success') {
        const { Content } = data
        const { Result } = Content
        return Result
      }
    },
  })

  const updateMarketingImg = async () => {
    if (marketingImgReq.data) {
      const { content, link } = marketingImgReq.data

      const contentBuffer = Buffer.from(content, 'base64')
      const type = await fileTypeFromBuffer(contentBuffer)

      setMarketingImg({
        src: `data:${type.mime};base64,${content}`,
        extension: type.ext,
        link,
      })
    } else {
      setMarketingImg({
        src: ProductListIcon,
      })
    }
  }

  useEffect(() => {
    updateMarketingImg()
  }, [marketingImgReq.data])

  if (marketingImgReq.isLoading) {
    return (
      <div className={styles.containerProductImageLoaderDiv}>
        <CircularProgress color="success" />
      </div>
    )
  }

  if (!marketingImg.src) {
    return null
  }

  return (
    <div className={styles.containerProductImageDiv}>
      <a href={marketingImg.link} target="_blank" rel="noreferrer">
        <CCardImage
          orientation="top"
          src={marketingImg.src}
          className={styles.productListImgStyling}
        />
      </a>
    </div>
  )
}

MarketingImage.propTypes = {
  accountNumber: PropTypes.string.isRequired,
}

export default MarketingImage
