import { CCard } from '@coreui/react'
import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as BudgetIcon } from '../../../assets/invoice_icons/budgetIcon.svg'

import styles from './bugdetCard.module.scss'
import { formatPrice } from 'src/utils/commonUtils'

const BudgetCard = ({ title, value }) => {
  return (
    <CCard className={styles.cardStyle}>
      <div className={styles.contentRowStyle}>
        <div>
          <div className={styles.cardTitle}>{title}</div>
          <div className={styles.cardSubtitle}>Estimated budget</div>
        </div>
        <div className={styles.budgetDivStyle}>
          <div className={styles.budgetIconStyle}>
            <BudgetIcon />
          </div>
          <div>
            <div className={styles.budgetValueStyle}>{formatPrice(value)}</div>
            <div className={styles.totalBudgetTextStyle}>Total Budget</div>
          </div>
        </div>
      </div>
    </CCard>
  )
}

BudgetCard.defaultProps = {
  title: '',
  value: 0,
}

BudgetCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
}

export default BudgetCard
